const CaseData = [

    {
        "id":1,
        "imageUrl":"https://homepages.cae.wisc.edu/~ece533/images/airplane.png",
        "title":"Airplane",
        "buttonUrl":"/Airplane"
        
    }
,    {
        "id":2,
        "imageUrl":"https://homepages.cae.wisc.edu/~ece533/images/cat.png",
        "title":"Cats",
        "buttonUrl":"/Cats"
        
    }
    ,    {
        "id":3,
        "imageUrl":"https://homepages.cae.wisc.edu/~ece533/images/lena.png",
        "title":"Lena",
        "buttonUrl":"/Lena"
        
    }
    ,    {
        "id":4,
        "imageUrl":"https://homepages.cae.wisc.edu/~ece533/images/zelda.png",
        "title":"Zelda",
        "buttonUrl":""
        
    }
    ,    {
        "id":5,
        "imageUrl":"https://homepages.cae.wisc.edu/~ece533/images/peppers.png",
        "title":"Peppers",
        "buttonUrl":""
        
    }

    ,    {
        "id":2,
        "imageUrl":"https://homepages.cae.wisc.edu/~ece533/images/cat.png",
        "title":"Cats",
        "buttonUrl":"Cat"
        
    }
    ,    {
        "id":2,
        "imageUrl":"https://homepages.cae.wisc.edu/~ece533/images/cat.png",
        "title":"Cats",
        "buttonUrl":"Cat"
        
    }
    ,    {
        "id":2,
        "imageUrl":"https://homepages.cae.wisc.edu/~ece533/images/cat.png",
        "title":"Cats",
        "buttonUrl":"Cat"
        
    }

        
    

]
    
    export default CaseData
