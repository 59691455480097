import React,{Component} from 'react'
import Map from './Map'
import ContactData from './ContactData'
import Identity from './Identity'

class ContactUs extends Component
{
    state=
    {
        data: ContactData
    };
    render()
    {
        const dataToRender=this.state.data.map(item => <Identity key={item.id} data={item} /> );
        const socialService="FF  TT  YY  IS WS"
        return (

          <div><h2 className="text-center"  style={{padding : '10',color: "white" ,fontFamily: "sans-serif"}}>Reach to Us</h2>
         <div class="container-fluid">
          <div class="card-deck" >
          
         
               <div class="card" style={{backgroundColor:"inherit",color:"#bbb",border:0}}>
                       <Map />
                </div>
                
                
  <div class="card text-center" style={{backgroundColor:"inherit",color:"#bbb",border:0}}>
    
      
    {dataToRender}
    {socialService}

  </div>

</div>
</div>
</div>

















        );
    }
}

export default ContactUs