import React from 'react';

function Nav(props){
    return(
              <nav className="navbar navbar-light tortinental-nav">
                 <a className="navbar-brand title-text" href="#">
                     <img src={props.image} width="30" height="30" className="d-inline-block align-top mr-1" alt=""></img>
                         {props.site}
                </a>
               </nav>
    );
}
export default Nav;
