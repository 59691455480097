import React, { useState } from 'react';
//import styled from 'styled-components';
import '../assets/scss/ShowCaseItem.scss';


function ShowCaseItem(props){
  
  const [count, setCount] = useState(0);
    return(
       
          <div className="show-container">
              <div className="card">
                <div className="img-container" onClick={()=>console.log("Clicked on Container")}>
                     <img class="card-img-top img-responsive" src={props.caseItems.imageUrl} alt="Airplane"></img>
                   <div class="ButtonContainer" onClick={()=>console.log("Ckicked link")}>
                   <a target="_blank" href={props.caseItems.buttonUrl} onClick={props.callBack} >Click Me</a>     
              </div>
              
            
                <div class="card-footer d-flex  justify-content-between">
              <p className="alignn-self-center text-center mb-0"> {props.caseItems.title}</p>
                </div>
               </div>
              </div>
            
          </div>
         
    );
}
export default ShowCaseItem;
