import React, { useState } from 'react';
import '../assets/scss/footer.scss'
function FooterContent(){
    return(
       <span>Test footer with <a href="">link to nothing</a></span>
    );
};

function NavFooter() {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);

  return (
      <footer>
        <div className='footer'>
                {FooterContent()} 
        </div>
        <div className='placeholder-footer'>&nbsp;</div>
      </footer>
  );
}
export default NavFooter;
