import React from 'react';
import MultiClamp from 'react-multi-clamp';

class InfoCard extends React.Component{
    constructor(props){
        super();
        this.details = props.details;
        this.titleClamp = props.titleClamp || 3 ;
        this.descriptionClamp = props.description || 3 ;
    }
    render(){
        return(
            <div className={this.details.className+" card"} >
                    {(this.details.imageUrl?
              <img className="card-img-top" src={this.details.imageUrl} />
                  :"")}
              <div className="card-body">
                  <h5 className="card-title">
                    <MultiClamp ellipsis=" ..." clamp={this.titleClamp}>{this.details.title}</MultiClamp>
                  </h5>
                    <p className="card-text">
                    <MultiClamp ellipsis=" ..." clamp={this.descriptionClamp}>{this.details.description}</MultiClamp>
                    </p>
                <a href={this.details.url} className="card-link" target="_blank">{this.details.urlPlaceholder}</a>
              </div>
            </div>
        );
    }
}

export default InfoCard;
