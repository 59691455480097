import React from 'react';
import './assets/scss/app.scss'
import * as CONSTANTS from './constants/site-constants.js';
import Nav from './components/navBar';
import NavFooter from './components/footer';
import LandingPage from './page-contents/landingPage'

class App extends React.Component{
    constructor(){
        super();
        this.handleScroll = this.handleScroll.bind(this);
    }
    handleScroll(event){
        let element = document.querySelector('.placeholder')
        let  nav= document.querySelector('.tortinental-nav')
        if(element.getBoundingClientRect().y+ nav.offsetHeight < 0){
            nav.classList.add('scrolled-up');
        }else{
            nav.classList.remove('scrolled-up');
        }
    }
    render(){

        const info = {className:"display-shelf",imageUrl:"test.png",title:"Card Title",description:"simple description",url:"#"};
        return (
            <div className="app-container" onScroll={this.handleScroll} >                
                <Nav image={process.env.PUBLIC_URL+'/assets/images/mainLogo.jpeg'} site={CONSTANTS.SITE_NAME}/>
                <div className="w-100 placeholder"></div>
                <LandingPage handleHover={this.handleHover}/>
                <NavFooter/>
            </div>
        );

    }
}

export default App;
