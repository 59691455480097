import React, {Component } from 'react';

import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import GoogleMap from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;


class SimpleMap extends Component {
   
  static propTypes = {
    center: PropTypes.array,
    zoom: PropTypes.number,
    greatPlaceCoords: PropTypes.any
  };

  static defaultProps = {
    center: [59.938043, 30.337157],
    zoom: 4,
    greatPlaceCoords: {lat: 59.724465, lng: 30.080121}
  };


  constructor(props) {
    super(props);
  }
 
  render() {
    return (
      // Important! Always set the container height explicitly
      <div class="card-body bg-inherit" style={{height:'300px',width: '100%' ,padding: '0',margin:0}}>
        <GoogleMap
          bootstrapURLKeys={{ key: 'AIzaSyCTL7I_k1ouYJXVgBTIQ38u0jpYwC2fXCE' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={30.955413}
            lng={30.337844}
            text="My Marker"
          />
        </GoogleMap>
      </div>
    );
  }
}
 
export default SimpleMap;
