const ContactData = [

{
    "id":1,
    "name":"Abinesh",
    "role":"30",
    "email":"abineshsiva191@gmail.com",
    "mobile":"9597632876"
}]

export default ContactData