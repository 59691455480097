import React,{Component} from 'react'

class Identity extends Component
{
    
    
    render()
    {
        return(
            <div class="card-body" style={{height:'300px',width: '100%' ,padding: '0',margin:10}}>
                     <address>
              <strong>{this.props.data.name}</strong><br />
              <a href="mailto:#">{this.props.data.email} </a>
            </address>
                        
                            <address>
                                Chennai<br />
                                1234  Street Name<br />
                                Tamil Nadu, India<br />
                                <abbr title="Phone"></abbr> {this.props.data.mobile} 
                             </address>
            
          
            </div>
                        
        );
    }
}
export default Identity
