
import React from 'react';
import ContactUs from '../components/ContactUs';
import InstaFeed from './InstaContent'
import '../assets/scss/landingPage.scss'
import ShowCaseItem from '../components/ShowCaseItem';
import CaseData from '../components/CaseData'
function LandingPage(props){
    const showItem = CaseData.map(item => <ShowCaseItem key={item.id} caseItems={item} callBack={()=>console.log("test")}></ShowCaseItem>); 
    return (
                <div className="content container" onMouseOver={props.handleHover} >
                        <h1>Hand made Pasteries with an eye for details</h1>
                            <div className="showcase">
                              <div className="row">
                                   {showItem}
                                </div>
                            </div>
                        <h2>Everyone deserves a Unique Pastery....</h2>
                    <InstaFeed/>
                    <p className="col-12 p-2"></p>
                        <h2>Contact Us</h2>
                    <ContactUs />
                </div>
    );
}
export default LandingPage;
