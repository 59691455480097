import React from 'react';
import axios from 'axios';
import InfoCard from '../components/InfoCard';
import $ from 'jquery'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import '../assets/scss/InstaContent.scss';
import {log} from 'util';

// Getting data from Instagram API, you don't have one? Let's take a look there https://www.instagram.com/developer/

function getInstagramData() {
const userid = 'self';
const secret = '14438084256.f820a93.14f19fc398fd427eb826d9f0e25025c3';
const limit  = '5';
// This URL is applied to user feed, if you want to change this with whatever you want here is the place to visit https://www.instagram.com/developer/endpoints/

const url = 'https://api.instagram.com/v1/users/'+userid+'/media/recent/?access_token='+secret+'&count='+limit+'&callback=?';
var value = axios.get(url).then(function(response){ return response.data}).catch(function(value){console.log(value)});
console.log(value);
  return value;
}
class InstaFeed extends React.Component{
    constructor(){
        super();
        this.state = {data:[]};
    }
    componentDidMount(){
        getInstagramData().then(response=>{this.setState({data:response.data})});
        
    }
    componentDidUpdate(){
        (function ( $ ) { 
            console.log($('.owl-carousel'));
            $('.owl-carousel').owlCarousel(
                {
                        items:3,
                        margin:10,
                        loop:true,
                        slideTransition: 'linear',
                        autoplay:true,
                        autoplayTimeout:3000,
                        autoplaySpeed: 2000,
                        autoplayHoverPause:true,
                        autoHeight:true
                }
            );

        })(window.$);
    }
    render(){
        const feed = this.state.data;
        var details = {};
        var description = "";
        return (
                <div className='w-100 h-100 row'>
                        <div class="owl-carousel owl-theme">
                            {feed.map((item,index)=>(
                                description="❤️" + item.likes.count+" 💬 "+item.comments.count,
                                    details = {className:"item insta",
                                                imageUrl:item.images.standard_resolution.url,
                                                title:item.caption.text,
                                                description:description,
                                                url:item.link,
                                                urlPlaceholder:"Open in Insta"
                                    },
                                    console.log(item),
                                    <InfoCard details={details}/>
                                ))}
                       </div>
                    </div>
        
        );
    };
} 
export default InstaFeed;
